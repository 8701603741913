import React from "react";
import cn from "classnames";
import { isEmpty, isEqual, isNumber, isNil, omit, isString, isDate } from "lodash";
import { listToAnyOf } from "./form";
import { dateStringToJsonDate, dateToJson, getDateValueForInput, localizeJsonDate } from "./date";
import { isNullOrWhitespace } from "./string";
import { TrueFalse } from "./constants";

export const ATTRIBUTE_MAX_LENGTH = 55;

export const AttributeFieldType = {
    TEXT: "1",
    TEXTAREA: "2",
};

export const isNumericAttributeType = (typeName) => {
    return ["numeric", "limitednumeric"].includes((typeName || "").toLowerCase());
};

export const isDateAttributeType = (typeName) => {
    return ["date", "date with limits"].includes((typeName || "").toLowerCase());
};

export const isEqualAttributeName = (attributeName = "", attribute) => {
    const name = attribute?.attributeName ?? attribute?.attributename ?? attribute?.event_attr_name ?? "";

    return name.toLowerCase() === attributeName.toLowerCase();
};

export const isValidationTypeNumeric = (validationType) => {
    return [135, 136].includes(parseInt(validationType));
};

export const isValidationTypeDate = (validationType) => {
    return [327, 897].includes(parseInt(validationType));
};

export const isReadOnlyAttribute = (attribute) => {
    return attribute.editAll === "N";
};

export const isHiddenAttribute = (attribute) => {
    return attribute.showAll === "N";
};

/**
 * Check if Low/High limits are required for catalog or event attributes
 * @param {object} attribute - attribute
 * @param {boolean} isEventAttribute - is this attribute an event attribute
 * @returns
 */
export const isLowHighLimitRequired = (attribute, isEventAttribute) => {
    // For events, Low/High limits for numeric types only. Ticket V50-5592
    if (isEventAttribute && !isValidationTypeNumeric(attribute.validationType)) {
        return false;
    }

    return [136, 897].includes(attribute.validationType);
};

export const isAttributeWithLookups = ({ attributeName }) => (attributeName ?? "").toLowerCase().endsWith("_sel");

export const hasLookupValues = (attribute) => {
    return (attribute?.lookupValues || []).length > 0;
};

export const getAttributeTitle = (friendlyName, attributeName) => {
    if (!isNil(friendlyName)) {
        return String(friendlyName).toUpperCase();
    }

    return formatAttributeName(attributeName, "&nbsp;");
};

/**
 * Formats an attribute name.
 *
 * @param {string | undefined} attributeName - The attribute name to format.
 * @param {string} emptyValue - The value to return if the attribute name is empty.
 * @returns {string} The formatted attribute name.
 */
export const formatAttributeName = (attributeName, emptyValue = "") => {
    return attributeName
        ? String(attributeName)
              .trim()
              .split("_")
              .filter((i) => !isEmpty(i))
              .join(" ")
              .toUpperCase()
        : emptyValue;
};

export const getValidationTypeName = (attribute, validationTypes) => {
    return validationTypes.filter((i) => Number(i.val) === Number(attribute.validationType)).map((i) => i.display)[0];
};

export const getCatalogAttributeName = (attribute, availableAttributes) => {
    return availableAttributes
        .filter((a) => a.productAttrStandardNumber === attribute.productAttrStandardNumber)
        .map((a) => a.productAttrDescription)[0];
};

export const getEventAttributeName = (attribute, availableAttributes) => {
    return availableAttributes.filter((a) => a.eventAttrTypeNumber === attribute.eventAttrTypeNumber).map((a) => a.eventAttrDescription)[0];
};

export const valueToString = (value, attribute) => {
    if (isValidationTypeNumeric(attribute.validationType)) {
        const number = Number(value);
        return isNaN(number) || number === null ? undefined : String(value);
    }

    return isEmpty(value) ? undefined : String(value);
};

export const getAttributeSchema = ({
    attributeName,
    friendlyName,
    validationTypeName,
    lookupValues,
    lowerLimit,
    upperLimit,
    attributeMaxLength,
    validationOther,
    fieldType,
}) => {
    const isNumericAttribute = isNumericAttributeType(validationTypeName);
    const isDateAttribute = isDateAttributeType(validationTypeName);

    const numberMinimum = !isNil(lowerLimit) &&
        isNumericAttribute && {
            minimum: Number(lowerLimit),
        };

    const numberMaximum = !isNil(upperLimit) &&
        isNumericAttribute && {
            maximum: Number(upperLimit),
        };

    const dateMinimum = !isNil(lowerLimit) &&
        isDateAttribute && {
            formatMinimum: lowerLimit === "today" ? dateToJson(new Date()) : dateStringToJsonDate(lowerLimit),
        };

    const dateMaximum = !isNil(upperLimit) &&
        isDateAttribute && {
            formatMaximum: upperLimit === "today" ? dateToJson(new Date()) : dateStringToJsonDate(upperLimit),
        };

    const maxLength = attributeMaxLength
        ? {
              maxLength: attributeMaxLength,
          }
        : {};

    const attributeTitle = getAttributeTitle(friendlyName, attributeName);
    const hasLookups = (lookupValues || []).length > 0 && !isDateAttribute;

    let isNumericTypeMismatch = false;
    let type = isNumericAttribute && fieldType !== AttributeFieldType.TEXTAREA ? "number" : "string";

    if (hasLookups && isNumericAttribute && lookupValues.some((item) => !isNumber(item.lookup))) {
        isNumericTypeMismatch = true;
        type = "string";
    }

    return {
        [attributeName]: {
            type,
            title: attributeTitle,
            ...numberMinimum,
            ...numberMaximum,
            ...dateMinimum,
            ...dateMaximum,
            ...maxLength,
            ...(hasLookups
                ? {
                      anyOf: listToAnyOf({
                          list: lookupValues,
                          map: (item) => ({
                              title: item.lookup,
                              enum: [type === "string" ? item.lookup : Number(item.lookup)],
                          }),
                          sort: false,
                      }),
                  }
                : {}),
            isNumericTypeMismatch,
            validationOther,
        },
    };
};

export const getAttributeUiSchema = ({
    attributeName,
    validationTypeName,
    friendlyNameToolTip,
    lookupValues = [],
    showAll,
    editAll,
    fieldType,
}) => {
    let widget = isDateAttributeType(validationTypeName) ? "date" : hasLookupValues({ lookupValues }) ? "select" : "text";

    if (fieldType === AttributeFieldType.TEXTAREA) {
        widget = "textarea";
    }

    // Mark attribute as hidden. Visible only for users with specific rights
    const hiddenAttribute = isHiddenAttribute({ showAll });

    return {
        [attributeName]: {
            classNames: cn({
                "hidden-attribute": hiddenAttribute,
            }),
            "ui:widget": widget,
            ...(widget === "date" ? { "ui:overlap": true } : {}),
            "ui:time": false,
            "ui:readonly": isReadOnlyAttribute({ editAll }),
            "ui:hiddenAttribute": hiddenAttribute,
            "ui:help": friendlyNameToolTip,
        },
    };
};

export const getNormalizedAttributeValue = ({ value, validationTypeName, isNumericTypeMismatch = false }) => {
    if (isNumericAttributeType(validationTypeName) && !isNumericTypeMismatch) {
        const newValue = value?.toString().replace(/,/, "");
        return isNil(newValue) || isNaN(Number(newValue)) ? undefined : Number(newValue);
    }

    return isNil(value) || value === "" ? undefined : String(value);
};

export const getAttributeInitialValues = ({ attributeName, validationTypeName, attributes, defaultValue, attributeValueKey }) => {
    const defaultVal = getNormalizedAttributeValue({
        value: defaultValue,
        validationTypeName,
    });

    const attributeVal = attributes
        .filter((attr) => isEqualAttributeName(attributeName, attr))
        .filter((_, index) => index === 0)
        .map((attr) =>
            getNormalizedAttributeValue({
                value: attr[attributeValueKey],
                validationTypeName,
            })
        )[0];

    return {
        [attributeName]: attributeVal ?? defaultVal,
    };
};

export const getAttributesSubmitData = ({ attributes, resource, attributeNumberKey }) => {
    return attributes
        .filter((a) => !isNil(a[attributeNumberKey]) && !isNil(a.validationType)) // Filter out valid attributes
        .filter((a) => isAttributeChanged(a, resource)) // Filter out changed and new attributes
        .map((a) => ({ ...a, editStatus: a._existing ? 2 : 1 })) // Set Edited / Created status
        .map((a) => omit(a, ["_id", "_existing", "_selected", "_error"])) // Remove temporary properties
        .map((a) => ({
            ...a,
            // Limits are expected to be a string in backend
            lowerLimit: isNil(a.lowerLimit) ? undefined : String(a.lowerLimit),
            upperLimit: isNil(a.upperLimit) ? undefined : String(a.upperLimit),
            // Save empty values as null
            friendlyName: isNullOrWhitespace(a.friendlyName) ? null : a.friendlyName,
            friendlyNameToolTip: isNullOrWhitespace(a.friendlyNameToolTip) ? null : a.friendlyNameToolTip,
            dataDescription: isNullOrWhitespace(a.dataDescription) ? null : a.dataDescription,
            // Localize default date value to be in the same format as attribute value
            defaultValue: isValidationTypeDate(a.validationType) ? localizeJsonDate(a.defaultValue) : a.defaultValue,
        }));
};

export const isAttributeChanged = (attribute, resource) => {
    let result = true;

    const attributes = (resource && resource.attributes) || [];
    const excludedProperties = ["editStatus", "attrNumber", "productAttrStandardNumber", "eventAttrTypeNumber"];

    if (attribute._existing) {
        const initialAttribute = attributes.filter((a) => a.attrNumber === attribute.attrNumber)[0];

        if (initialAttribute) {
            result = false;

            result = Object.keys(initialAttribute)
                .filter((k) => !excludedProperties.includes(k))
                .reduce((isChanged, key) => {
                    if (isChanged === false && key === "lookupValues") {
                        const initialLookups = initialAttribute[key] ?? [];
                        const updatedLookups = attribute[key] ?? [];
                        const isSameLength = initialLookups.length === updatedLookups.length;

                        isChanged =
                            !isSameLength ||
                            updatedLookups.some((item, index) => !isEqual(String(item.lookup), String(initialLookups[index]?.lookup)));

                        return isChanged;
                    }

                    if (isNil(attribute[key]) && isNil(initialAttribute[key])) {
                        return isChanged;
                    }

                    if (
                        !isNumber(attribute[key]) &&
                        isEmpty(attribute[key]) &&
                        !isNumber(initialAttribute[key]) &&
                        isEmpty(initialAttribute[key])
                    ) {
                        return isChanged;
                    }

                    if (!isEqual(String(attribute[key]), String(initialAttribute[key]))) {
                        isChanged = true;
                    }

                    return isChanged;
                }, false);
        }
    }

    return result;
};

export const getValidationTypeFromCSV = (line, validationTypes) =>
    Number((validationTypes.filter((i) => i.display === (line.VALIDATIONTYPE || "text"))[0] || {}).val);

export const getValidationReqFromCSV = (line) => ((line.VALIDATIONREQ || "").toUpperCase() === "TRUE" ? TrueFalse.True : TrueFalse.False);

export const getLookupValuesFromCSV = (line) =>
    (line.LOOKUPVALUES || "")
        .split("|")
        .map((v) => v.trim())
        .filter((v) => v.length > 0)
        .map((v) => ({ lookup: v }));

export const getAttributeFieldsFromCSV = (line, validationTypes) => {
    const validationType = getValidationTypeFromCSV(line, validationTypes);
    const validationReq = getValidationReqFromCSV(line);
    const lookupValues = getLookupValuesFromCSV(line);

    const lowerLimit = isNullOrWhitespace(line.LOWLIMIT)
        ? null
        : isValidationTypeNumeric(validationType)
        ? Number(line.LOWLIMIT)
        : line.LOWLIMIT;

    const upperLimit = isNullOrWhitespace(line.HIGHLIMIT)
        ? null
        : isValidationTypeNumeric(validationType)
        ? Number(line.HIGHLIMIT)
        : line.HIGHLIMIT;

    return {
        defaultValue: line.DEFAULTVALUE,
        itemOrder: Number(line.ITEMORDER),
        friendlyName: line.FRIENDLYNAME,
        friendlyNameToolTip: line.TOOLTIP,
        dataDescription: line.DATADESC,
        validationType,
        validationOther: line.VALIDATIONOTHER,
        validationReq,
        lowerLimit,
        upperLimit,
        showAll: (line.SHOWALL ?? "").toUpperCase(),
        editAll: (line.EDITALL ?? "").toUpperCase(),
        lookupValues,
    };
};

/**
 * Validate attribute fields uploaded from CSV.
 * Throws validation errors
 *
 * @param {Object} params
 * @param {Object} params.attribute - attribute with values from CSV line
 * @param {Object} params.line - line from CSV
 * @param {number} params.lineNumber - line number
 * @param {string} params.attributeName - attribute name
 * @param {number} params.attributeMaxLength - max length of attribute
 * @param {boolean} params.isCatalog - is this attribute a catalog attribute
 */
export const validateAttributeFromCSV = ({ attribute, line, lineNumber, attributeName, attributeMaxLength, isCatalog }) => {
    if (!attribute.validationType) {
        throw Error(`Line ${lineNumber}. Invalid VALIDATIONTYPE ${line.VALIDATIONTYPE}`);
    }

    // Validate default value
    if (!isEmpty(attribute.defaultValue)) {
        if (isValidationTypeNumeric(attribute.validationType)) {
            const defaultValueNumber = Number(attribute.defaultValue);

            if (isNaN(defaultValueNumber)) {
                throw Error(`Line ${lineNumber}. Invalid DEFAULTVALUE for ${attribute.validationType}`);
            }
        }

        if (isValidationTypeDate(attribute.validationType)) {
            const dateCheck = Date.parse(attribute.defaultValue);

            if (!isString(attribute.defaultValue) && !isDate(attribute.defaultValue) && isNaN(dateCheck)) {
                throw Error(`Line ${lineNumber}. Invalid DEFAULTVALUE for ${attribute.validationType}`);
            }
        }

        if (isNumber(attributeMaxLength) && String(attribute.defaultValue).length > attributeMaxLength) {
            throw Error(`Line ${lineNumber}. Invalid DEFAULTVALUE`);
        }
    }

    const low = attribute.lowerLimit;
    const high = attribute.upperLimit;

    // Validate low high limits
    if (isLowHighLimitRequired(attribute, !isCatalog)) {
        if (!low) {
            throw Error(`Line ${lineNumber}. Missing LOWLIMIT ${line.LOWLIMIT}`);
        }

        if (!high) {
            throw Error(`Line ${lineNumber}. Missing HIGHLIMIT ${line.HIGHLIMIT}`);
        }

        if (isValidationTypeNumeric(attribute.validationType)) {
            const lowNumber = Number(low);
            const highNumber = Number(high);

            if (isNaN(lowNumber)) {
                throw Error(`Line ${lineNumber}. Invalid LOWLIMIT for ${attribute.validationType}`);
            }

            if (isNaN(highNumber)) {
                throw Error(`Line ${lineNumber}. Invalid HIGHLIMIT for ${attribute.validationType}`);
            }
        } else {
            const dateCheckLow = Date.parse(low);
            const dateCheckHigh = Date.parse(high);

            if (!isString(low) && !isDate(low) && isNaN(dateCheckLow)) {
                throw Error(`Line ${lineNumber}. Invalid LOWLIMIT for Date`);
            }

            if (!isString(high) && !isDate(high) && isNaN(dateCheckHigh)) {
                throw Error(`Line ${lineNumber}. Invalid HIGHLIMIT for Date`);
            }
        }
    }

    // If both low and high values are present validate if LOWLIMIT smaller than HIGHLIMIT
    if ([low, high].every((i) => !isNil(i))) {
        if (isValidationTypeDate(attribute.validationType)) {
            const lowDate = getDateValueForInput({ value: low });
            const highDate = getDateValueForInput({ value: high });

            if (!isNil(lowDate) && !isNil(highDate) && lowDate >= highDate) {
                throw Error(`Line ${lineNumber}. LOWLIMIT Should be smaller than HIGHLIMIT`);
            }
        }

        if (isValidationTypeNumeric(attribute.validationType)) {
            const lowNumber = Number(low);
            const highNumber = Number(high);

            if (lowNumber >= highNumber) {
                throw Error(`Line ${lineNumber}. LOWLIMIT Should be smaller than HIGHLIMIT`);
            }
        }
    }

    // Validate Show All
    if (!["Y", "N"].includes(attribute.showAll)) {
        throw Error(`Line ${lineNumber}. Invalid SHOWALL ${line.SHOWALL}`);
    }

    // Validate Edit All
    if (!["Y", "N"].includes(attribute.editAll)) {
        throw Error(`Line ${lineNumber}. Invalid EDITALL ${line.EDITALL}`);
    }

    // Validate lookupvalues
    if (attribute.lookupValues?.length > 0 && !isAttributeWithLookups({ attributeName })) {
        throw Error(
            `Line ${lineNumber}. Lookup values not allowed for attribute "${attributeName}". Lookup values are only allowed on attributes ending in "_SEL".`
        );
    }
};

export const isAttributePlaceholderLine = ({ line }) => {
    const catalogItemPropKeys = ["CATALOGID", "CATALOGNUMBER", "NAME"];

    return Object.keys(line ?? {})
        .filter((key) => !catalogItemPropKeys.includes(key))
        .map((key) => (line[key] ?? "").trim())
        .every(isEmpty);
};

export const getAttributesImportFromCsvErrorText = ({ error }) => {
    let errorMessage = error.message;

    if (error.type === "API_CRUD_READ_ERROR") {
        errorMessage = `${error.message} ${error.passThroughData?.resourceId}`;
    }

    return (
        <>
            <strong>Attributes Import failed</strong>
            <p>{errorMessage}</p>
        </>
    );
};

export const validateAttributesForm = (formData, errors, schema) => {
    Object.keys(schema.properties ?? {}).forEach((attributeKey) => {
        if (!isNil(formData[attributeKey])) {
            // Add error if attribute value has type mismatch and value is not a number
            if (schema.properties[attributeKey].isNumericTypeMismatch && isNaN(formData[attributeKey])) {
                errors[attributeKey].addError("Must be numeric");
            }

            // Add error if attribute value does not match regex in validationOther
            if (schema.properties[attributeKey].validationOther) {
                let regex;
                const regexString = schema.properties[attributeKey].validationOther;
                if (regexString.startsWith("/")) {
                    // Separate pattern and flags
                    const match = regexString.match(/^\/(.*)\/([gimuy]*)$/);
                    const pattern = match[1];
                    const flags = match[2];
                    regex = new RegExp(pattern, flags);
                } else {
                    regex = new RegExp(regexString);
                }
                if (!regex.test(formData[attributeKey])) {
                    errors[attributeKey].addError(`Must match pattern ${regexString}`);
                }
            }
        }
    });

    return errors;
};
